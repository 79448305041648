$breadcrumb-item-color: $link !default;
$breadcrumb-item-hover-color: $link-hover !default;
$breadcrumb-item-active-color: $text-strong !default;

$breadcrumb-item-padding-vertical: 0 !default;
$breadcrumb-item-padding-horizontal: 0.75em !default;

$breadcrumb-item-separator-color: $grey-light !default;

.breadcrumb {
  @include block;
  @include unselectable;
  font-size: $size-normal;
  white-space: nowrap;
  a {
    align-items: center;
    color: $breadcrumb-item-color;
    display: flex;
    justify-content: center;
    padding: $breadcrumb-item-padding-vertical $breadcrumb-item-padding-horizontal;
    &:hover {
      color: $breadcrumb-item-hover-color; } }
  li {
    align-items: center;
    display: flex;
    &:first-child a {
      padding-left: 0; }
    &.is-active {
      a {
        color: $breadcrumb-item-active-color;
        cursor: default;
        pointer-events: none; } }
    & + li::before {
      color: $breadcrumb-item-separator-color;
      content: "\0002f"; } }
  ul,
  ol {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; }
  .icon {
    &:first-child {
      margin-right: 0.5em; }
    &:last-child {
      margin-left: 0.5em; } }
  // Alignment
  &.is-centered {
    ol,
    ul {
      justify-content: center; } }
  &.is-right {
    ol,
    ul {
      justify-content: flex-end; } }
  // Sizes
  &.is-small {
    font-size: $size-small; }
  &.is-medium {
    font-size: $size-medium; }
  &.is-large {
    font-size: $size-large; }
  // Styles
  &.has-arrow-separator {
    li + li::before {
      content: "\02192"; } }
  &.has-bullet-separator {
    li + li::before {
      content: "\02022"; } }
  &.has-dot-separator {
    li + li::before {
      content: "\000b7"; } }
  &.has-succeeds-separator {
    li + li::before {
      content: "\0227B"; } } }
